/* eslint-disable no-await-in-loop */
/* eslint-disable no-unsafe-optional-chaining */
import { Button, Form, Input, Row, Col, Select, Tabs, message, Card, List, Breadcrumb } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Inputmask from 'inputmask';
import { FaChevronRight } from 'react-icons/fa';
import { AppointmentApi } from '../../../../../services/api';
import { validateCNPJ } from '../../../../Admin/assets/scripts/global';
import statesCities from '../../../../Website/assets/scripts/statesCities.json';

function JuridicalPersonRegisterComponent() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  // const navigate = useNavigate();

  //-----
  const [formData, setFormData] = useState({
    addressType: '',
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: '',
    contactType: '',
    contactEmail: ''
  });
  const [showAddressCard, setShowAddressCard] = useState(false);
  const [showContactCard, setShowContactCard] = useState(false);

  // funcao chamada ao clicar no botao adicionar card de endereço
  const handleAddCardAddress = () => {
    if (formData.addressType && formData.cep && formData.state && formData.city && formData.neighborhood && formData.street && formData.number) {
      setShowAddressCard(true);
    } else {
      // Validação: alertar se campos obrigatórios não forem preenchidos
      alert('Preencha todos os campos obrigatórios antes de adicionar o card.');
    }
  }

  const handleAddCardContacts = () => {
    setShowContactCard(true);
    // if (formData.contactType && formData.contactEmail) {
    //   setShowContactCard(true);
    // }
  }

  // funcao para manipular mudancas no formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value});
  };

  const handleSelectedChange = (value , name) => {
    const selectedOption = contactTypesOptions.find(option => option.value === value);

    setFormData({ ...formData, [name]: selectedOption.label });
  }

  const handleSelectChange = (value, name) => {
    setFormData({ ...formData, [name]: value});
  };

  //------------

  const [loading, setLoading] = useState(false);
  const [jpTypeOptions, setJPTypeOptions] = useState();
  const [addressTypesOptions, setAddressTypesOptions] = useState();
  const [stateOptions, setStateOptions] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [contactTypesOptions, setContactTypesOptions] = useState();

  const submit = async (data) => {
    console.log(data);
  };

  // Get Pessoa Juridica pelo Id
  const getById = async () => {
    setLoading(true);

    const response = await AppointmentApi.get(`/JuridicalPersons?id=${editId}`)
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        message.error('Erro desconhecido!');
      });

    form.setFieldsValue(response);
    setLoading(false);
  };

  const getAdressByCEP = async (cep) => {
    console.log(form.getFieldsValue());
    if (cep.replace('-', '').replaceAll('_', '')?.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => {
          const body = res.data;
          const data = {
            st_street: body.logradouro,
            st_district: body.bairro,
            st_city: body.localidade,
            st_state: body.uf,
          };

          form.setFieldsValue({ ...form.getFieldsValue(), ...data });
        })
        .catch((err) => {
          console.log(err);
          message.error('CEP inválido!');
        });
    }
  };

  const getJuridicalPersonTypes = async () => {
    const response = await AppointmentApi.get('/JuridicalPersons/GetJuridicalType')
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        message.error('Erro desconhecido!');
      });

    setJPTypeOptions(response);
  };

  const getAddressTypes = async () => {
    const response = await AppointmentApi.get('/AddressTypes/GetTypeAddress')
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        message.error('Erro desconhecido!');
      });

    setAddressTypesOptions(response);
  };

  const getContactTypes = async () => {
    const response = await AppointmentApi.get('ContactTypes/GetTypeContact')
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        message.error('Erro desconhecido!');
      });
    setContactTypesOptions(response);
  }

  const getStates = async () => {
    setStateOptions(
      statesCities.states.map(({ name, acronym }) => ({ label: name, value: acronym }))
    );
  };

  const getCities = async (state) => {
    //setCityOptions(cityOptions.states.map(({ name, acronym }) => ({ label: name, value: name })));
    const { citys: cities } = statesCities.states.find(({ acronym }) => acronym === state);
    setCityOptions(cities.map((city) => ({ label: city, value: city })));
  };

  useEffect( () => {
  }, [stateOptions])

  useEffect(() => {
    getJuridicalPersonTypes();
    getAddressTypes();
    getStates();
    getContactTypes();

    Inputmask({ mask: '99999-999' }).mask(document.getElementById('st_zip_code'));
    Inputmask({ mask: '99.999.999/9999-99' }).mask(document.getElementById('st_cnpj'));

    if (editId) {
      getById();
    }
  }, []);

  return (
    <div className="page-content collaborators-register">
      <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="card">
          <div className="card-header d-flex">
            <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
              <Breadcrumb.Item>
                <Link to="/Apontamento/Pessoa-Juridica">Pessoa Jurídica</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? 'Edição' : 'Registro'} de Pessoa Jurídica</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="card-body">
            <Form
              form={form}
              disabled={loading}
              name="juridicalPersonForm"
              layout="vertical"
              onFinish={submit}
              autoComplete="off"
            >
              <Tabs>
                <Tabs.TabPane tab="Pessoa Jurídica" key="1">
                  <Row gutter={[24]}>
                    <Form.Item
                      name="cd_user"
                      style={{ display: 'none' }}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Col span={8}>
                      <Form.Item
                        label="Nome"
                        name="st_name"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Operação"
                        name="st_operation"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="CNPJ"
                        name="st_cnpj"
                        rules={[
                          {
                            required: true,
                            validator: (rule, value) => {
                              const isValid = validateCNPJ(value ?? '');
                              if (!isValid) {
                                return Promise.reject(new Error(`CNPJ Inválido`));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input id="st_cnpj" placeholder="00.000.000/0000-00" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Total de Funcionários"
                        name="it_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Valor Hora"
                        name="dc_biling"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Tipo de PJ"
                        name="cd_juridical_person_type"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Select
                          options={jpTypeOptions}
                          allowClear
                          placeholder="Selecione"
                          optionFilterProp="label"
                          showSearch
                          dropdownStyle={{ borderRadius: 16 }}
                          disabled={editId}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Contatos" key="contacs" forceRender>
                  <Row gutter={[24]}>
                    <Col span={10}>
                      <Form.Item
                        label="Tipo de Contato"
                        name="cd_contact_type"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Select
                          options={contactTypesOptions}
                          allowClear
                          placeholder="Selecione"
                          optionFilterProp="label"
                          showSearch
                          dropdownStyle={{ borderRadius: 16 }}
                          name='contactType'
                          value={formData.contactType}

                          onChange={(value) => handleSelectedChange(value, 'contactType')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item
                        label="Telefone/Email"
                        name="st_content"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          name='contactEmail'
                          value={formData.contactEmail}
                          onChange={handleChange}
                          //onChange={(value) => handleChange(value, 'contactEmail')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Button style={{ marginTop: '31px'}} loading={loading} block type="primary" onClick={handleAddCardContacts}>
                        Adicionar
                      </Button>
                    </Col>

                    <Col span={4}>
                      { showContactCard && (
                        <Card title="Contato" bordered={false} style={{ width: 300 }}>
                          <p><strong>Tipo de Contato</strong> {formData.contactType}</p>
                          <p><strong>Telefone/E-mail</strong> {formData.contactEmail}</p>
                        </Card>
                      )}
                      {/*<List*/}
                      {/*  size="small"*/}
                      {/*  bordered*/}
                      {/*  renderItem={(item) => <List.Item>{item}</List.Item>}*/}
                      {/*/>*/}
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Endereços" key="addresses" forceRender>
                  <Row gutter={[24]}>
                    <Col span={8}>
                      <Form.Item
                        label="Tipo de Endereço"
                        name="cd_address_type"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Select
                          options={addressTypesOptions}
                          allowClear
                          placeholder="Selecione"
                          optionFilterProp="label"
                          showSearch
                          dropdownStyle={{ borderRadius: 16 }}
                          disabled={editId}
                          value={formData.addressType}
                          onChange={(value) => {
                            handleSelectChange(value, 'addressType');
                          }}
                          //onChange={(value) => handleSelectChange(value, 'addressType')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="CEP"
                        name="st_zip_code"
                        onChange={() => {
                          //getAdressByCEP(form.getFieldValue('st_zip_code'));
                        }}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          id="st_zip_code"
                          name="cep"
                          placeholder="00000-000"
                          value={formData.cep}
                          onChange={handleChange}
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Estado"
                        name="st_state"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Select
                          options={stateOptions}
                          allowClear
                          placeholder="Selecione"
                          optionFilterProp="label"
                          showSearch
                          dropdownStyle={{ borderRadius: 16 }}
                          disabled={editId}
                          onChange={(value) => {
                            getCities(value);
                            handleSelectChange(value, 'state')
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Cidade"
                        id="idDaCidade"
                        name="st_city"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Select
                          options={cityOptions}
                          allowClear
                          placeholder="Selecione"
                          optionFilterProp="label"
                          showSearch
                          dropdownStyle={{ borderRadius: 16 }}
                          disabled={editId}
                          onChange={(value) => handleSelectChange(value, 'city')}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Bairro"
                        name="st_district"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          name="neighborhood"
                          value={formData.neighborhood}
                          onChange={handleChange}
                          placeholder="Digite o nome do bairro"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Rua"
                        name="st_street"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          name="street"
                          value={formData.street}
                          onChange={handleChange}
                          placeholder="Digite o nome da rua"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Número"
                        name="it_number"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          name="number"
                          value={formData.number}
                          onChange={handleChange}
                          placeholder="Numero da sua residencia"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Complemento"
                        name="st_address_details"
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório!',
                          },
                        ]}
                      >
                        <Input
                          name="complement"
                          value={formData.complement}
                          onChange={handleChange}
                          placeholder="Complemento"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Button style={{ marginTop: '31px'}} loading={loading} block type="default" onClick={handleAddCardAddress}>
                        Adicionar
                      </Button>
                    </Col>
                    <Col span={12}>
                      {/* Renderização Condicional do Card */}
                      {showAddressCard && (
                        <Card title="Endereço" bordered={true}
                              headStyle={{ color: '#d9d9d9'}}
                              style={{ color: 'white', backgroundColor: '#434343', marginTop: '20px' }}>
                          <Card type="inner" style={{ color: '#fafafa', backgroundColor: '#595959' }}>
                            <p><strong>Tipo de Endereço:</strong> {formData.addressType}</p>
                            <p><strong>CEP:</strong> {formData.cep}</p>
                            <p><strong>Estado:</strong> {formData.state}</p>
                            <p><strong>Cidade:</strong> {formData.city}</p>
                            <p><strong>Bairro:</strong> {formData.neighborhood}</p>
                            <p><strong>Rua:</strong> {formData.street}</p>
                            <p><strong>Número:</strong> {formData.number}</p>
                            <p><strong>Complemento:</strong> {formData.complement}</p>
                          </Card>
                        </Card>
                      )}
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
              <Button style={{ marginTop: '10px'}} loading={loading} type="dashed" htmlType="submit">
                Salvar
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

const JuridicalPersonRegister = memo(JuridicalPersonRegisterComponent);
export default JuridicalPersonRegister;
