import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import './style.scss';
import { scrollTop } from '../../assets/scripts/global';

export default function VacancyCard(props) {
  const attributes = props;

  return (
    <>
      <div className="d-flex flex-column vacancy-card">
        <Link to={`/Carreer-details?cd_vacancy=${attributes.cd_vacancy}`} onClick={scrollTop}>
          {attributes.title} <span>{attributes.level}</span> <p>{attributes.essentialLang}</p>
        </Link>
        <p className="custom-label">
          {attributes.regime} <BsArrowRight size="42" />
        </p>
      </div>
    </>
  );
}
