import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Spin, Input } from 'antd';
import { hasCookies, alertOptions, getCookie } from '../../assets/scripts/global';
import { AppointmentApi } from '../../../../services/api';

import './style.scss';
import logo from '../../../../img/logo-light.png';
import loader from '../../../../img/loader.svg';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(false);
  const [cookiesValue, setcookiesValue] = useState(false);

  // No login o alerta fica na altura da navbar
  useEffect(() => {
    if (location.pathname === '/Login') {
      alertOptions.containerStyle.top = '10px';
    } else if (location.pathname !== '/Login') {
      alertOptions.containerStyle.top = '80px';
    }
  }, [location]);

  // Valida os campos
  function validateFields() {
    let isValid = true;
    const username = document.getElementById('username');
    const password = document.getElementById('password');

    if (username.value === '') {
      username.classList.add('border-danger');
      isValid = false;
    } else {
      username.classList.remove('border-danger');
    }

    if (password.value === '') {
      password.classList.add('border-danger');
      isValid = false;

    } else {
      password.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição para logar
  async function login() {
    if (validateFields()) {
      document.getElementById('submitLogin').disabled = true;
      document.getElementById('arrow').style.display = 'none';
      document.getElementById('spin').style.display = 'flex';

      // Dados a ser enviados
      const data = JSON.stringify({
        St_login: document.getElementById('username').value,
        St_password: document.getElementById('password').value,
        Keep_connected: document.getElementById('keepLogged').checked,
      });

      // Requisição
      await AppointmentApi.post('/Login', data)
        .then((res) => {
          setUpdate(true);

          if (res.status === 200 || res.status === 201) {
            const date = new Date();
            date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
            date.toUTCString();

            // Cria os cookies
            if (document.getElementById('keepLogged').checked) {
              document.cookie = `nbw__login=${
                document.getElementById('username').value
              }; expires=${date}; path=/; SameSite=none; secure;`;

              document.cookie = `nbw__password=${
                document.getElementById('password').value
              }; expires=${date}; path=/; SameSite=none; secure;`;

              document.cookie = `nbw__keepConnected=true; expires=${date}; path=/; SameSite=none; secure;`;
            }

            // Cria a "session"
            localStorage.setItem('nbw__userData', res.data.userData);
            localStorage.setItem('nbw__permissions', JSON.stringify(res.data.permissions));
            localStorage.setItem('nbw__token', res.data.token);

            if (localStorage.getItem('nbw__permissions')) {
              window.location.href = '/Dashboard';
            }
          }
        })
        .catch((err) => {
          if (err.response === undefined) {
            toast.error('Servidor indisponível!');
          } else if (err.response.status === 500) {
            toast.error('Usuário ou senha inválido!');
          } else {
            toast.error(err.response.data);
          }
        });
      document.getElementById('submitLogin').style.disabled = false;
      document.getElementById('arrow').style.display = 'flex';
      document.getElementById('spin').style.display = 'none';
    }
  }

  // Loga o usuário automaticamente
  useEffect(() => {
    const checkCookies = async () => {
      if (getCookie('nbw__keepConnected') !== '' || localStorage.getItem('nbw__token')) {
        setcookiesValue(false);
        await hasCookies();
      } else {
        setcookiesValue(true);
      }
    };
    checkCookies();
  }, []);

  return (
    <>
      {cookiesValue ? (
        /* Card Esquerda */
        <div className="login-row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left-card">
            <img src={logo} title="NBW Digital" alt="Logo NBW Digital" />
            <h2 className="title-3 text-color-dark mt-5">TRANSFORMAÇÃO DIGITAL PARA</h2>
            <h2 className="title-3 text-color-dark">OTIMIZAR RESULTADOS</h2>
          </div>

          {/* Card Direita */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right-card p-5">
            <div className="card">
              <div className="card-body p-5">
                <h2 className="title-2 text-color-dark text-center mb-4 mt-4">LOGIN</h2>
                {/* Campos */}
                <label className="form-label text-color-dark" htmlFor="username">
                  Usuário
                  <input id="username" type="text" className="form-control" />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="form-label text-color-dark mt-2" htmlFor="password">
                  Senha
                  <Input.Password
                    id="password"
                    type="password"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        login();
                      }
                    }}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </label>
                <div className="form-check mt-3">
                  <label className="form-check-label" htmlFor="keepLogged">
                    <input id="keepLogged" className="form-check-input me-3" type="checkbox" />
                    Lembre de mim
                  </label>
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button
                    id="submitLogin"
                    className="btn btn-primary"
                    onClick={login}
                    type="button"
                  >
                    <FaArrowRight size="24" color="#fff" id="arrow" />
                    <Spin
                      id="spin"
                      style={{ marginLeft: 10, color: 'white', display: 'none' }}
                      indicator={<LoadingOutlined spin />}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            style={{ marginTop: '250px' }}
            height="100px"
            color="#36b4e5"
            alt="Loader"
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
}
