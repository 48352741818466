/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Input, Space, Table, Form, Dropdown, Menu, DatePicker } from 'antd';
import { useState, useContext, createContext, useEffect, useRef } from 'react';
import { ReloadOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AppointmentApi } from '../../../../services/api';

export default function TableAntd({
  columnsTable,
  loadingTable,
  dataTable,
  routePermissions,
  nameOfResquest,
  getData,
  setData,
  cdPage,
  route,
  isIgnore,
  notEdit,
  scroll,
  btnNew,
  menuOfPage,
  defaultPageSize,
  isNotAction,
  notCode,
  notDelete,
  selection,
}) {
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const userData = JSON.parse(localStorage.getItem('nbw__userData'));
  const permissions = JSON.parse(localStorage.getItem('nbw__permissions'));
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const EditableContext = createContext(null);
  const [key, setKey] = useState();
  const [loadingTableComponent, setLoadingTableComponent] = useState();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState();

  function permissionsFilter() {
    const permissionsFiltered = permissions.filter(({ resource }) => resource === routePermissions);
    return permissionsFiltered;
  }

  useEffect(() => {

    if (dataTable === undefined || dataTable.length === 0) {
      document.querySelector(`#${cdPage} .ant-table-body`).style.borderRadius = '0 0 16px 16px';
    } else {
      document.querySelector(`#${cdPage} .ant-table-body`).style.borderRadius = '0px';
    }
  }, [dataTable]);

  const handleSave = async (row) => {
    const dataEdit = [...dataTable];
    const index = dataEdit.findIndex((item) => row[cdPage] === item[cdPage]);
    const item = dataEdit[index];
    dataEdit.splice(index, 1, { ...item, ...row });
    setData(dataEdit);

    // eslint-disable-next-line no-param-reassign
    delete row.cd_situation;
    // eslint-disable-next-line no-param-reassign
    delete row.st_situation;
    const dataEdt = {
      ...row,
      cd_user: userData.id,
    };
    await AppointmentApi.put(`/${nameOfResquest}`, dataEdt);
  };

  const handleDelete = async () => {
    setLoadingTableComponent(true);
    setTimeout(async () => {
      await AppointmentApi.delete(`/${nameOfResquest}?id=${key}&${userData.id}`).then(() => {
        setData(dataTable.filter((item) => item[cdPage] !== key));
      });
      setLoadingTableComponent(false);
    }, 1000);
  };

  const getColumnSearchProps = (dataIndex, title, isDate) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {isDate ? (
          <DatePicker
            onChange={(date, dateString) => setSelectedKeys([dateString])}
            format="YYYY-MM-DD"
            style={{ marginBottom: 8, display: 'block' }}
            placeholder={`Pesquise ${title}`}
          />
        ) : (
          <Input
            id="inputSearch"
            placeholder={`Pesquise ${title}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            // eslint-disable-next-line no-use-before-define
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        )}
        <Space>
          <Button
            type="primary"
            // eslint-disable-next-line no-use-before-define
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#4280dc',
              borderColor: '#4280dc',
              borderRadius: '16px',
            }}
          >
            Pesquisar
          </Button>
          <Button
            // eslint-disable-next-line no-use-before-define
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90, borderRadius: '16px' }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && !isDate) {
        setTimeout(() => document.getElementById('inputSearch').value, 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? <p>{text}</p> : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // eslint-disable-next-line react/prop-types
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  function EditableCell({ title, editable, children, dataIndex, record, ...restProps }) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        alert.error('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const menu = (keyItem) => (
    <Menu>
      {permissionsFilter()[0].delete && !notDelete && (
        <Menu.Item
          key="0"
          data-bs-toggle="modal"
          data-bs-target="#excludeModal"
          onClick={() => setKey(keyItem)}
          style={notEdit && { borderRadius: '16px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
              }}
              data-bs-toggle="modal"
              data-bs-target="#excludeModal"
              onClick={() => setKey(keyItem)}
            >
              Excluir
            </button>
          </div>
        </Menu.Item>
      )}
      {menuOfPage ? menuOfPage(keyItem) : null}
    </Menu>
  );

  const columns = () => {
    if (!isNotAction && notCode) {
      return [
        ...columnsTable(getColumnSearchProps),
        {
          title: 'Ação',
          width: '70px',
          dataIndex: '',
          key: 'operation',
          fixed: 'right',
          render: (_, record) => (
            <Dropdown
              style={{ cursor: 'pointer' }}
              overlay={menu(record[cdPage])}
              trigger={['click']}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="ant-dropdown-link">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  ...
                </span>
              </div>
            </Dropdown>
          ),
        },
      ];
    }
    if (!isNotAction && !notCode) {
      return [
        {
          title: 'Código',
          dataIndex: cdPage,
          key: cdPage,
          width: '110px',
          ...getColumnSearchProps(cdPage, 'Código'),
          sorter: (a, b) => a[cdPage] - b[cdPage],
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'ascend',
          render: (text, record) =>
            !notEdit ? (
              <span
                role="presentation"
                style={{ color: '#1890fd', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    isIgnore
                      ? `${route}?${cdPage}=${record[cdPage]}`
                      : `${route.split('?')[0]}?${cdPage}=${record[cdPage]}`
                  );
                }}
              >
                {text}
              </span>
            ) : (
              <span>{text}</span>
            ),
        },
        ...columnsTable(getColumnSearchProps),
        {
          title: 'Ação',
          width: '70px',
          dataIndex: '',
          key: 'operation',
          fixed: 'right',
          render: (_, record) => (
            <Dropdown
              style={{ cursor: 'pointer' }}
              overlay={menu(record[cdPage])}
              trigger={['click']}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="ant-dropdown-link">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  ...
                </span>
              </div>
            </Dropdown>
          ),
        },
      ];
    }
    return [...columnsTable(getColumnSearchProps)];
  };

  // eslint-disable-next-line no-param-reassign
  const columnsTable2 = columns().map((col) => {

    const permissions = permissionsFilter();
    const hasEditPermission = permissions && permissions.length > 0 && permissions[0].edit;

    if (!col.editable || !hasEditPermission){
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const hasInsertPermission = permissions && permissions.length > 0 && permissions[0].insert;
  const headerTable = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          {hasInsertPermission && btnNew ? (
            <Link id="new" className="btn btn-primary me-2" to={`${route}`}>
              Novo
            </Link>
          ) : null}
          <Button
            style={{ borderRadius: '16px', alignItems: 'center' }}
            icon={<ReloadOutlined />}
            onClick={() => getData()}
            className="btn-reload-table"
          />
        </div>
        <p className="allRegisters-table">Total de Registros: {dataTable ? dataTable.length : 0}</p>
      </div>
    </>
  );

  // eslint-disable-next-line no-shadow
  const onSelectedRowKeysChangeClient = async (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    selection(selectedRowKeys);
  };

  const selectRow = async (record) => {
    setSelectedRowKeys([record.key]);
    selection(record.key);
  };

  const onRow = (record) => {
    if (selection !== undefined) {
      return {
        onClick: async () => {
          selectRow(record);
        },
      };
    }
    return null;
  };

  const rowSelection = () => {
    if (selection !== undefined) {
      return {
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectedRowKeysChangeClient,
      };
    }
    return null;
  };

  return (
    <>
      {headerTable()}
      <Table
        columns={columnsTable2}
        loading={{
          spinning: loadingTableComponent || loadingTable,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        }}
        dataSource={dataTable}
        sticky
        showSorterTooltip={{ title: 'Clique para mudar a ordenação' }}
        components={components}
        size="middle"
        bordered
        tableLayout="auto"
        id={cdPage}
        rowSelection={rowSelection()}
        onRow={(record) => onRow(record)}
        scroll={pageSize > 10 ? { ...scroll, y: 410 } : { ...scroll }}
        pagination={{
          onShowSizeChange: (e, pageSizeChange) => setPageSize(pageSizeChange),
          position: ['bottomCenter'],
          size: 'default',
          defaultPageSize: defaultPageSize || 10,
        }}
        rowClassName={(index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
      />
      {/* Modal Excluir */}
      <div
        className="modal fade modal-custom"
        id="excludeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="excludeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-icon">
              <FaExclamationTriangle size="40" className="exclamation-triangle" />
            </div>
            <div className="modal-header">
              <h4 className="modal-title mt-5 mb-2 second-title">
                Deseja realmente excluir este registro?
              </h4>
            </div>
            <div className="modal-body" />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleDelete();
                }}
              >
                Excluir
              </button>
              <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
