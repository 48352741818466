/* eslint-disable no-bitwise */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import ReactGA from 'react-ga';

import '../../../../custom.scss';
import '../../website.scss';
import './style.scss';

import logo from '../../../../img/logo-light.png';

function changeNavBarStyle() {
  setTimeout(() => {
    if (window.location.href.split('/')[3] === '') {
      document.getElementById('navbar').classList.remove('navbar-black');
    } else {
      document.getElementById('navbar').classList.add('navbar-black');
    }

    document.getElementById('hamburger-1').classList.remove('is-active');
    document.getElementById('navbarNavAltMarkup').classList.remove('show');
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, 10);
}

export default function Navbar() {
  // Google Analytics
  ReactGA.initialize('UA-200283130-1');

  // Bitrix24 - Formulário Cadastro de Empresa
  (() => {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://cdn.bitrix24.com.br/b18784749/crm/form/loader_16.js?${
      (Date.now() / 180000) | 0
    }`;
    s1.setAttribute('data-b24-form', 'inline/16/4v4u9o');
    s1.setAttribute('data-skip-moving', 'true');
    s0.parentNode.insertBefore(s1, s0);
  })();

  // Bitrix24 - Botão CRM
  (() => {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://cdn.bitrix24.com.br/b18784749/crm/site_button/loader_2_0kxdsb.js?${
      (Date.now() / 60000) | 0
    }`;
    s0.parentNode.insertBefore(s1, s0);
  })();

  useEffect(() => {
    if (window.location.href.split('/')[3] === '') {
      document.getElementById('navbar').classList.remove('navbar-black');
    } else {
      document.getElementById('navbar').classList.add('navbar-black');
    }
  });

  // Evento botão hamburger
  function toggleHamburger() {
    document.getElementById('hamburger-1').classList.toggle('is-active');
    document.getElementById('navbarNavAltMarkup').classList.toggle('show');
  }

  return (
    <nav id="navbar" className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container-fluid">
        <Link to="/" onClick={() => changeNavBarStyle()} className="navbar-brand">
          <img src={logo} title="NBW Digital" alt="Logo NBW Digital" />
        </Link>
        <div
          className="hamburger"
          id="hamburger-1"
          role="button"
          tabIndex="0"
          onClick={toggleHamburger}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              toggleHamburger()
            }
          }}
        >
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </div>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link to="/" onClick={changeNavBarStyle} className="nav-link">
              Início
            </Link>
            <Link to="/About" onClick={changeNavBarStyle} className="nav-link">
              Quem somos
            </Link>
            <Link to="/Services" onClick={changeNavBarStyle} className="nav-link">
              Serviços
            </Link>
            <Link to="/Contact" onClick={changeNavBarStyle} className="nav-link">
              Contato
            </Link>
            <Link to="/Carreers" onClick={changeNavBarStyle} className="nav-link py-0 px-0">
              <button type="button" className="btn btn-primary" color="#dd2a7b">
                 Envie seu currículo
              </button>
            </Link>
          </div>

          <div className="navbar-nav nav-icons">
            <div className="social-circle">
              <a
                className="facebook-icon"
                href="https://www.facebook.com/nbwdigital/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <FaFacebookF size="20" />
              </a>
            </div>
            <div className="social-circle">
              <a
                className="linkedin-icon"
                href="https://www.linkedin.com/company/nbwdigital/"
                target="_blank"
                rel="noreferrer"
                title="Linkedin"
              >
                <FaLinkedinIn size="20" />
              </a>
            </div>
            <div className="social-circle">
              <a
                className="instagram-icon"
                href="https://www.instagram.com/nbwdigital/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <FaInstagram size="20" />
              </a>
            </div>
            <div className="social-circle">
              <a
                className="whatsapp-icon"
                href="https://api.whatsapp.com/send?phone=554733044033&text=Oi,%20tudo%20bem?"
                target="_blank"
                rel="noreferrer"
                title="Whatsapp"
              >
                <FaWhatsapp size="20" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
