import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import Async from 'react-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VacancyCard from '../../components/VacancyCard';
import { AppointmentApi } from '../../../../services/api';
import { validateEmail } from '../../assets/scripts/global';

import './style.scss';

import employee1 from '../../assets/img/EmployeesPhotos/employee1.jpeg';
import employee2 from '../../assets/img/EmployeesPhotos/employee2.jpeg';
import employee3 from '../../assets/img/EmployeesPhotos/employee3.jpg';
import employee4 from '../../assets/img/EmployeesPhotos/employee4.jpeg';
import employee5 from '../../assets/img/EmployeesPhotos/employee5.jpg';
import employee6 from '../../assets/img/EmployeesPhotos/employee6.jpeg';

import loader from '../../../../img/loader.svg';

export default function Carreers() {

  // Responsividade do carrosel
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // Limpar campos
  function clearFields() {
    document.getElementById('nameField').value = '';
    document.getElementById('emailField').value = '';
    document.getElementById('fileField').value = '';
    document.getElementById('messageField').value = '';
  }

  // Envia email para o RH
  async function sendEmail() {
    document.getElementById('send').disabled = true;

    const formdata = new FormData();
    formdata.append('type', 'email');
    formdata.append('subject', 'Banco de talentos');
    formdata.append('name', document.getElementById('nameField').value);
    formdata.append('email', document.getElementById('emailField').value);
    formdata.append('file', document.getElementById('fileField').files[0]);
    formdata.append('message', document.getElementById('messageField').value);
    formdata.append('path', 1);

    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    // Requisição
    try {
      const res = await AppointmentApi.post('/Emails/Career', formdata, headers);
      if (res.status === 200 || res.status === 201) {
        toast.success(res.data);
      }
    } catch (err) {
      toast.error(err.response.data);
    } finally {
      clearFields();
      document.getElementById('send').disabled = false;
    }
  }

  // Validação campos preenchidos
  function validateFields() {
    let isValid = true;
    const nameField = document.getElementById('nameField');
    const emailField = document.getElementById('emailField');
    const cvFileField = document.getElementById('fileField');
    const messageField = document.getElementById('messageField');

    if (nameField.value === '') {
      nameField.classList.add('border-danger');
      isValid = false;
    } else {
      nameField.classList.remove('border-danger');
    }

    if (emailField.value === '') {
      emailField.classList.add('border-danger');
      isValid = false;
    } else if (validateEmail(emailField.value)) {
      emailField.classList.remove('border-danger');
    } else {
      emailField.classList.add('border-danger');
      toast.error('E-mail inválido!');
      document.getElementById('send').disabled = false;
      return false;
    }

    if (cvFileField.value === '') {
      cvFileField.classList.add('border-danger');
      isValid = false;
    } else {
      cvFileField.classList.remove('border-danger');
    }

    if (messageField.value === '') {
      messageField.classList.add('border-danger');
      isValid = false;
    } else {
      messageField.classList.remove('border-danger');
    }

    if (isValid) {
      sendEmail();
      //toast.success("Obrigado por escolher a nbw digital, entraremos em contato o quando antes.");
    } else {
      toast.error('Campos obrigatórios não preenchidos!');
    }
    return isValid;
  }

  useEffect(() => {
    validateFileExtension();
    //Inputmask({ mask: '(99) [9] 9999-9999' }).mask(document.getElementById('phoneField'));
  });

  // function para validar input file
  function validateFileExtension() {
    const file = document.getElementById('fileField');

    if (file && file !== undefined) {
      file.onchange = () => {
        if (file.value) {
          if (file.value.split('.')[1].toLowerCase() !== 'pdf') {
            toast.error('Insira um arquivo PDF!');
            file.value = '';
            document.innerText = 'Nenhum arquivo selecionado';
          } else {
            const fileName = file.value.split('\\')[2].split('.')[0];
            document.innerText = fileName;
          }
        }
      };
    }
  }

  // Busca lista de vagas no endpoint
  const loadVacancies = () =>
    AppointmentApi.get('/Vacancies/GetAll')
      .then((res) => res.data)
      .catch((err) => err.response.data);

  return (
    <>
      <ToastContainer />
      {/* Carousel partners */}
      <div className="container carousel-testimony">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 className="title-2 mb-md-5 mb-0 text-center">O QUE NOSSA GALERA DIZ</h2>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Carousel
              swipeable
              draggable
              responsive={carouselResponsive}
              ssr
              infinite
              autoPlay
              autoPlaySpeed={8000}
              keyBoardControl
              customTransition="all .5 linear"
              transitionDuration={500}
              removeArrowOnDeviceType={['mobile']}
              itemClass="text-center"
            >
              <div className="d-flex flex-column align-items-center">
                <img src={employee6} alt="Foto Cristiano Franco" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>Trabalhar na NBW é uma constante sensação de fazer parte
                  da empresa. Tenho o CEO no whatsapp e ele responde prontamente em caso de dúvidas.
                  Ao meu ver, essa é uma das principais vantagens em trabalhar aqui...praticamente
                  zero burocracia. Além disso, a autonomia e confiança que a NBW deposita em mim me
                  faz sentir respeitado e valorizado como profissional.<strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Cristiano Franco</p>
                <p className="subtitle-2 text-color-dark">Product Owner</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={employee1} alt="Foto Thiago Baumgartner" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>Atendemos as diferentes necessidades dos negócios.
                  Buscamos o aprimoramento constante de nossos profissionais para mantermos a
                  competitividade. Colocamos a necessidade do cliente como fator fundamental para
                  continuarmos nosso legado de sucesso.<strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Thiago Baumgartner</p>
                <p className="subtitle-2 text-color-dark">Diretor de Operações</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={employee2} alt="Foto Jackson Glatz" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>Viver a transformação digital é pensar em
                  sustentabilidade. É pensar em reutilizar e usar cada vez menos recursos para
                  executar ainda mais tarefas. A NBW Digital é o lugar onde podemos transformar
                  soluções, realizar nossas ambições e deixar um legado mais sustentável ao mundo.
                  <strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Jackson Glatz</p>
                <p className="subtitle-2 text-color-dark">Diretor de Negócios</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={employee3} alt="Foto Jades Roberto Patrício" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>Aqui na NBW Digital tenho oportunidade de trabalhar
                  trocando experiência com pessoas de gerações mais novas que a minha. Isto faz toda
                  a diferença para manter minha mente jovem e ligada nas atualizações do mercado.
                  <strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Jades Roberto Patrício</p>
                <p className="subtitle-2 text-color-dark">Líder de Desenvolvimento</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={employee4} alt="Foto Tharlison da Silva Conceição" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>A NBW Digital é o lugar com oportunidades incríveis, onde
                  sou desafiado todos os dias como profissional, seja em qualquer tecnologia ou
                  metodologia. Agradeço a família NBW Digital por me propor um ótimo ambiente de
                  trabalho e a convivência com pessoas incríveis!<strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Tharlison da Silva Conceição</p>
                <p className="subtitle-2 text-color-dark">Analista Desenvolvedor</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={employee5} alt="Foto Thiago Gomes de Oliveira" />
                <p className="text-justify mt-md-5 mt-4 mb-md-4">
                  <strong>&rdquo;</strong>Aqui temos tudo o que um profissional precisa para atingir
                  o seu sucesso. Oportunidades estão sempre ao nosso redor, todos os dias! Basta
                  estarmos preparados para agarrar elas e fazer o nosso melhor. Estou muito feliz em
                  fazer parte deste time.<strong>&ldquo;</strong>
                </p>
                <p className="subtitle-2 text-color-dark">Thiago Gomes Oliveira</p>
                <p className="subtitle-2 text-color-dark">Desenvolvedor</p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      {/* Career Opportunities */}
      <Async promiseFn={loadVacancies}>
        <Async.Loading>
          <h2 className="title-2 text-center">OPORTUNIDADES DE CARREIRA</h2>
          <div className="row align-items-start">
            <img src={loader} height="120px" color="#36b4e5" alt="Loader" />
          </div>
        </Async.Loading>
        <Async.Fulfilled>
          {(data = []) => (
            <div className="container vacancy-container">
              <div className="row align-items-start">
                <h2 className="title-2 text-center">OPORTUNIDADES DE CARREIRA</h2>
                {data.map((vacancy) => (
                  <div
                    key={vacancy.cd_vacancy}
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center"
                  >
                    <VacancyCard
                      cd_vacancy={vacancy.vacancyCode}
                      title={vacancy.vacancy}
                      level={vacancy.knowledgeLevel}
                      essentialLang={vacancy.requirements.split(';').map((str, index) => (
                        <React.Fragment key={index}>
                          {str}
                          <br />
                        </React.Fragment>
                      ))}
                      regime={`${vacancy.city}/${vacancy.state}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Async.Fulfilled>
        <Async.Rejected>{() => toast.error('Erro ao buscar vagas!')}</Async.Rejected>
      </Async>

      {/* Send Email HR */}
      <div className="container contact-rh d-flex justify-content-center">
        <div className="row text">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fadeIn">
            <h2 className="title-3 text-start text-md-center">
              NÃO ENCONTROU A OPORTUNIDADE QUE PROCURAVA?
            </h2>
            <p className="subtitle text-start text-md-center">
              Preencha o formulário abaixo para se cadastrar em nosso banco de talentos
            </p>
            <div className="mb-3 mt-md-5 mt-3">
              <label className="form-label" htmlFor="nameField">
                Nome completo*
                <input type="text" className="form-control" id="nameField" />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="emailField">
                E-mail*
                <input
                  type="text"
                  className="form-control"
                  id="emailField"
                  placeholder="exemplo@email.com"
                />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="fileField">
                Anexe seu currículo*
                <input className="form-control" type="file" id="fileField" />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="messageField">
                Mensagem*
                <textarea className="form-control" id="messageField" rows="8" />
              </label>
            </div>
            <div className="d-grid gap-2">
              <button
                id="send"
                type="button"
                className="btn btn-primary d-block"
                onClick={validateFields}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

