import React, { useState } from 'react';
import { Upload, Breadcrumb } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import domtoimage from 'dom-to-image';

import ImgCrop from 'antd-img-crop';

import './style.scss';

import logo from '../../../../img/logo-light.png';

export default function ProjectList() {
  const [stateName, setStateName] = useState('Nome');
  const [stateJobRole, setStateJobRole] = useState('Cargo');
  const [stateEmail, setStateEmail] = useState('exemplo@nbwdigital.com.br');
  const [fileList, setFileList] = useState([]);
  const [hasPhoto, setHasPhoto] = useState(false);

  const handleChange = async ({ fileList: newFileList }) => {
    if (!newFileList[0]) {
      return false;
    }
    if (newFileList[0]?.type !== 'image/jpeg' && newFileList[0]?.type !== 'image/png') {
      toast.error('O arquivo precisa ser JPEG ou PNG!');
      return false;
    }
    await setFileList(newFileList);
    return true;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleRemove = async () => {
    setFileList([]);
  };

  const SelectPicture = async () => {
    document.getElementById('picture').setAttribute('src', fileList[0].thumbUrl);
    document.getElementById('picture').style.display = 'block';
    setHasPhoto(true);
  };

  const FillName = async () => {
    setStateName(document.getElementById('nameField').value);

    if (document.getElementById('nameField').value === '') {
      setStateName('Nome');
    }
  };

  const FillJobRole = async () => {
    setStateJobRole(document.getElementById('jobRoleField').value);

    if (document.getElementById('jobRoleField').value === '') {
      setStateJobRole('Cargo');
    }
  };

  const FillEmail = async () => {
    setStateEmail(document.getElementById('emailField').value);

    if (document.getElementById('emailField').value.split('@')[0] === '') {
      setStateEmail('exemplo@nbwdigital.com.br');
    }
  };

  const GenerateSignature = async () => {
    domtoimage.toPng(document.getElementById('emailTemplate'), { quality: 1 }).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'assinatura-email-nbwdigital.png';
      link.href = dataUrl;
      link.click();
    });
  };

  return (
    <>
    <ToastContainer />
      <div className="page-content">
        <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Assinatura de Email</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Form */}
              <div className="row mb-3">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3"
                  htmlFor="nameField"
                >
                  Nome
                  <input
                    id="nameField"
                    type="text"
                    onKeyUp={() => {
                      FillName();
                    }}
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3"
                  htmlFor="jobRoleField"
                >
                  Cargo
                  <input
                    id="jobRoleField"
                    type="text"
                    onKeyUp={() => {
                      FillJobRole();
                    }}
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3"
                  htmlFor="emailField"
                >
                  E-Mail
                  <input
                    id="emailField"
                    type="text"
                    onKeyUp={() => {
                      FillEmail();
                    }}
                  />
                </label>

                <div className="upload-photo">
                  <ImgCrop grid rotate>
                    <Upload
                      accept="image/*"
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      onRemove={handleRemove}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && 'Clique aqui e escolha uma foto'}
                    </Upload>
                  </ImgCrop>

                  {fileList.length > 0 ? (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      onClick={SelectPicture}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          SelectPicture();
                        }
                      }}
                    >
                      Confirmar
                    </button>
                  ) : (
                    <button className="btn btn-primary mt-2" disabled type="button">
                      Confirmar
                    </button>
                  )}
                </div>
              </div>

              {/* Template Email */}
              <div className="d-flex align-items-end">
                <div id="emailTemplate" className="template">
                  <div className="background">
                    <svg
                      width="25"
                      height="172"
                      viewBox="0 0 25 172"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="faixaBolinhas"
                    >
                      <circle cx="2" cy="149" r="2" fill="white" />
                      <circle cx="16" cy="149" r="2" fill="white" />
                      <circle cx="2" cy="163" r="2" fill="white" />
                      <circle cx="16" cy="163" r="2" fill="white" />
                      <circle cx="2" cy="170" r="2" fill="white" />
                      <circle cx="16" cy="170" r="2" fill="white" />
                      <circle cx="2" cy="79" r="2" fill="white" />
                      <circle cx="16" cy="79" r="2" fill="white" />
                      <circle cx="2" cy="135" r="2" fill="white" />
                      <circle cx="16" cy="135" r="2" fill="white" />
                      <circle cx="2" cy="65" r="2" fill="white" />
                      <circle cx="16" cy="65" r="2" fill="white" />
                      <circle cx="2" cy="121" r="2" fill="white" />
                      <circle cx="16" cy="121" r="2" fill="white" />
                      <circle cx="2" cy="51" r="2" fill="white" />
                      <circle cx="16" cy="51" r="2" fill="white" />
                      <circle cx="2" cy="107" r="2" fill="white" />
                      <circle cx="16" cy="107" r="2" fill="white" />
                      <circle cx="2" cy="37" r="2" fill="white" />
                      <circle cx="16" cy="37" r="2" fill="white" />
                      <circle cx="2" cy="93" r="2" fill="white" />
                      <circle cx="16" cy="93" r="2" fill="white" />
                      <circle cx="2" cy="23" r="2" fill="white" />
                      <circle cx="16" cy="23" r="2" fill="white" />
                      <circle cx="2" cy="9" r="2" fill="white" />
                      <circle cx="16" cy="9" r="2" fill="white" />
                      <circle cx="2" cy="142" r="2" fill="white" />
                      <circle cx="16" cy="142" r="2" fill="white" />
                      <circle cx="2" cy="156" r="2" fill="white" />
                      <circle cx="16" cy="156" r="2" fill="white" />
                      <circle cx="2" cy="72" r="2" fill="white" />
                      <circle cx="16" cy="72" r="2" fill="white" />
                      <circle cx="2" cy="128" r="2" fill="white" />
                      <circle cx="16" cy="128" r="2" fill="white" />
                      <circle cx="2" cy="58" r="2" fill="white" />
                      <circle cx="16" cy="58" r="2" fill="white" />
                      <circle cx="2" cy="114" r="2" fill="white" />
                      <circle cx="16" cy="114" r="2" fill="white" />
                      <circle cx="2" cy="44" r="2" fill="white" />
                      <circle cx="16" cy="44" r="2" fill="white" />
                      <circle cx="2" cy="100" r="2" fill="white" />
                      <circle cx="16" cy="100" r="2" fill="white" />
                      <circle cx="2" cy="30" r="2" fill="white" />
                      <circle cx="16" cy="30" r="2" fill="white" />
                      <circle cx="2" cy="86" r="2" fill="white" />
                      <circle cx="16" cy="86" r="2" fill="white" />
                      <circle cx="2" cy="16" r="2" fill="white" />
                      <circle cx="16" cy="16" r="2" fill="white" />
                      <circle cx="2" cy="2" r="2" fill="white" />
                      <circle cx="16" cy="2" r="2" fill="white" />
                      <circle cx="9" cy="149" r="2" fill="white" />
                      <circle cx="23" cy="149" r="2" fill="white" />
                      <circle cx="9" cy="163" r="2" fill="white" />
                      <circle cx="23" cy="163" r="2" fill="white" />
                      <circle cx="9" cy="170" r="2" fill="white" />
                      <circle cx="23" cy="170" r="2" fill="white" />
                      <circle cx="9" cy="79" r="2" fill="white" />
                      <circle cx="23" cy="79" r="2" fill="white" />
                      <circle cx="9" cy="135" r="2" fill="white" />
                      <circle cx="23" cy="135" r="2" fill="white" />
                      <circle cx="9" cy="65" r="2" fill="white" />
                      <circle cx="23" cy="65" r="2" fill="white" />
                      <circle cx="9" cy="121" r="2" fill="white" />
                      <circle cx="23" cy="121" r="2" fill="white" />
                      <circle cx="9" cy="51" r="2" fill="white" />
                      <circle cx="23" cy="51" r="2" fill="white" />
                      <circle cx="9" cy="107" r="2" fill="white" />
                      <circle cx="23" cy="107" r="2" fill="white" />
                      <circle cx="9" cy="37" r="2" fill="white" />
                      <circle cx="23" cy="37" r="2" fill="white" />
                      <circle cx="9" cy="93" r="2" fill="white" />
                      <circle cx="23" cy="93" r="2" fill="white" />
                      <circle cx="9" cy="23" r="2" fill="white" />
                      <circle cx="23" cy="23" r="2" fill="white" />
                      <circle cx="9" cy="9" r="2" fill="white" />
                      <circle cx="23" cy="9" r="2" fill="white" />
                      <circle cx="9" cy="142" r="2" fill="white" />
                      <circle cx="23" cy="142" r="2" fill="white" />
                      <circle cx="9" cy="156" r="2" fill="white" />
                      <circle cx="23" cy="156" r="2" fill="white" />
                      <circle cx="9" cy="72" r="2" fill="white" />
                      <circle cx="23" cy="72" r="2" fill="white" />
                      <circle cx="9" cy="128" r="2" fill="white" />
                      <circle cx="23" cy="128" r="2" fill="white" />
                      <circle cx="9" cy="58" r="2" fill="white" />
                      <circle cx="23" cy="58" r="2" fill="white" />
                      <circle cx="9" cy="114" r="2" fill="white" />
                      <circle cx="23" cy="114" r="2" fill="white" />
                      <circle cx="9" cy="44" r="2" fill="white" />
                      <circle cx="23" cy="44" r="2" fill="white" />
                      <circle cx="9" cy="100" r="2" fill="white" />
                      <circle cx="23" cy="100" r="2" fill="white" />
                      <circle cx="9" cy="30" r="2" fill="white" />
                      <circle cx="23" cy="30" r="2" fill="white" />
                      <circle cx="9" cy="86" r="2" fill="white" />
                      <circle cx="23" cy="86" r="2" fill="white" />
                      <circle cx="9" cy="16" r="2" fill="white" />
                      <circle cx="23" cy="16" r="2" fill="white" />
                      <circle cx="9" cy="2" r="2" fill="white" />
                      <circle cx="23" cy="2" r="2" fill="white" />
                    </svg>

                    <div className="backgroundPicture">
                      <img id="picture" title="Foto Usuário" alt="Foto Usuário" />
                      <svg
                        width="150"
                        height="150"
                        viewBox="0 0 150 150"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="75" cy="45" r="25" fill="#2D2D2E" />
                        <path
                          d="M74.6144 32C70.717 32 68.1927 33.5968 66.2108 36.4446C65.8513 36.9612 65.9621 37.6695 66.4636 38.0498L68.57 39.6469C69.0765 40.031 69.7971 39.9412 70.1936 39.4444C71.4167 37.9121 72.324 37.0299 74.2345 37.0299C75.7367 37.0299 77.5947 37.9966 77.5947 39.4533C77.5947 40.5544 76.6856 41.12 75.2024 41.9515C73.4728 42.9212 71.1839 44.1281 71.1839 47.1471V47.625C71.1839 48.2722 71.7086 48.7969 72.3558 48.7969H75.8944C76.5416 48.7969 77.0663 48.2722 77.0663 47.625V47.3431C77.0663 45.2503 83.1829 45.1632 83.1829 39.5C83.1829 35.2352 78.759 32 74.6144 32ZM74.1251 50.2353C72.2601 50.2353 70.7428 51.7526 70.7428 53.6177C70.7428 55.4827 72.2601 57 74.1251 57C75.9902 57 77.5075 55.4827 77.5075 53.6176C77.5075 51.7526 75.9902 50.2353 74.1251 50.2353Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.8213 124.69C19.8677 108.275 25.2374 92.77 35.7486 84.4634C58.4976 66.4859 100.424 69.5331 117.545 91.1988C124.171 99.5841 128.433 112.467 129.849 126.153C116.159 140.826 96.6508 150 75 150C52.6382 150 32.5622 140.214 18.8213 124.69Z"
                          fill="#2D2D2E"
                        />
                      </svg>
                    </div>

                    <div className="nameAndJobRole">
                      <p className="name">{stateName}</p>
                      <p className="jobRole">{stateJobRole}</p>
                    </div>

                    <p className="email">{stateEmail}</p>

                    <img
                      className="logo"
                      src={logo}
                      height="85px"
                      title="NBW Digital"
                      alt="Logo NBW Digital"
                    />

                    <div className="companyDetails">
                      <p className="websiteLink">www.nbwdigital.com.br</p>
                      <p className="phoneNumber">Phone: +55 (47) 3332-7853</p>
                    </div>
                  </div>
                </div>

                {stateName !== 'Nome' &&
                stateJobRole !== 'Cargo' &&
                stateEmail !== 'exemplo@nbwdigital.com.br' &&
                fileList.length > 0 &&
                hasPhoto ? (
                  <button
                    className="btn btn-success ms-3"
                    type="button"
                    onClick={GenerateSignature}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        GenerateSignature();
                      }
                    }}
                  >
                    Gerar Assinatura
                  </button>
                ) : (
                  <button className="btn btn-success ms-3" disabled type="button">
                    Gerar Assinatura
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
