import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../../services/api';
import './style.scss';
import TableAntd from '../../../../Admin/components/TableAntd';

export default function ProjectList() {
  const [data, setData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getData = async () => {
    setLoadingTable(true);
    try {
      const response = AppointmentApi.get('/Projects/GridProjects');
      const newResponse = response.data.map((item) => ({ ...item, key: item.cd_project }));
      setData(newResponse);
    } catch (err) {
      if (err.response === undefined) {
        toast.error('Servidor indisponivel!');
        setData([]);
      }
      setData([]);
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect( () => {
    const fetchData = async () => {
      await getData();
    }
    fetchData();
  }, []);

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'st_description',
        title: 'Projetos',
        key: 'st_description',
        ...getColumnSearchProps('st_description', 'Projects'),
        sorter: (a, b) => a.st_description.length - b.st_description.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'st_name',
        title: 'Cliente',
        key: 'st_name',
        ...getColumnSearchProps('st_name', 'Cliente'),
        sorter: (a, b) => a.st_name.length - b.st_name.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
      },
      {
        dataIndex: 'it_amount_hours',
        title: 'Total de Horas',
        key: 'it_amount_hours',
        ...getColumnSearchProps('it_amount_hours', 'amountHours'),
        sorter: (a, b) => a.it_amount_hours.length - b.it_amount_hours.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'dc_hour_value',
        title: 'Valor Hora',
        key: 'dc_hour_value',
        ...getColumnSearchProps('dc_hour_value', 'hourValue'),
        sorter: (a, b) => a.dc_hour_value.length - b.dc_hour_value.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
    ];
    return columns;
  };

  return (
    <>
      <div className="page-content">
        <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Lista de Projetos</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                nameOfResquest="projects"
                btnNew
                setData={setData}
                cdPage="cd_project"
                routePermissions="projects"
                route="/Apontamento/Projeto/Registro"
                getData={() => getData()}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
