import React from 'react';
import { RiFilePaper2Line, RiEyeLine, RiVipDiamondLine } from 'react-icons/ri';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import './style.scss';

import nbwSquare from '../../assets/img/quadrado_nbw.png';
import logoAbraweb from '../../assets/img/AboutLogos/logoAbraweb.png';
import logoAcate from '../../assets/img/AboutLogos/logoAcate.png';
import logoBlusoft from '../../assets/img/AboutLogos/logoBlusoft.png';
import logoNovaCoop from '../../assets/img/AboutLogos/logoNovaCoop.png';

export default function About() {
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 849, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  function CustomRightArrow() {
    return false;
  }
  function CustomLeftArrow() {
    return false;
  }

  let animationExecuted = false;

  window.addEventListener(
    'scroll',
    () => {
      if (window.location.href.split('/')[3] === 'About') {
        const bannerMission = document.getElementById('bannerMission');
        if (window.scrollY > bannerMission.offsetTop - 800 && !animationExecuted) {
          bannerMission.classList.add('fadeIn');
          document.getElementById('mission').classList.add('slide-left');
          document.getElementById('values').classList.add('slide-right');

          animationExecuted = true;
        }
      }
    },
    false
  );
  return (
    <>
      {/* About */}
      <div className="container about">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft">SOBRE NÓS</h2>
            <p className="subtitle fadeInLeft text-start">CONHEÇA A NBW DIGTAL</p>
            <p className="text-justify fadeIn">
              Fundada em 2012, na cidade de Gaspar/SC, somos especializados em Outsourcing de
              profissionais de TI, Fábrica de Software, Serviços de sustentação, Suporte a TI,
              OffShoring, entre outras soluções próprias. Nosso objetivo é voltado as necessidades
              de cada negócio, com foco na funcionalidade, dinamismo e resultado. Nossa cultura visa
              buscar cada vez mais novas alternativas para solucionar dificuldades de forma ágil,
              inovadora e sustentável.
            </p>
            <strong className="text-highlight fadeIn"> #SomosNBW #VemSerDigital</strong>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <img className="slide-right" src={nbwSquare} alt="Foto letreito logo NBW" />
          </div>
        </div>
      </div>

      {/* Mission, Vison, Values */}
      <div className="container banner-mission">
        <div className="container">
          <div className="row align-items-start" id="bannerMission">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="mission">
              <div className="d-flex flex-column align-items-center">
                <RiFilePaper2Line size="56" color="#fff" />
                <strong>Missão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Criar valor agregado através de soluções inovadoras e sustentaveis.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <div className="d-flex flex-column align-items-center">
                <RiEyeLine size="56" color="#fff" />
                <strong>Visão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Ser a melhor parceira em inovação e transformação digital.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="values">
              <div className="d-flex flex-column align-items-center">
                <RiVipDiamondLine size="56" color="#fff" />
                <strong>Valores</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Simplicidade; Agilidade; Inovação; Cooperação; Respeito e Sustentabilidade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carousel partners */}
      <div className="container carousel-container">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft mb-5">NOSSOS PARCEIROS</h2>
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={logoAbraweb} height="70px" alt="Logo Abraweb" />
            <img src={logoAcate} height="70px" alt="Logo Acate" />
            <img src={logoBlusoft} height="70px" alt="Logo Blusoft" />
            <img src={logoNovaCoop} height="70px" alt="Logo Nova Cooperativa" />
          </Carousel>
        </div>
      </div>
    </>
  );
}
