/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import moment from 'moment';
import { AppointmentApi } from '../../../../../services/api';
import TableAntd from '../../../../Admin/components/TableAntd';
import './style.scss';

export default function Candidates() {
  const [data, setData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getData = async () => {
    setLoadingTable(true);

    try {
      const response = await AppointmentApi.get('/Candidates/GetGrid');
      const newResponse = response.data.map((item) => ({ ...item, key: item.cd_candidate }))

      setData(newResponse);
    } catch (err) {
      if (err.response === undefined) {
        toast.error(err.message);
      }
      setData([]);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData();
    }
    fetchData();
  }, []);

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'st_full_name',
        title: 'Nome Completo',
        key: 'st_full_name',
        ...getColumnSearchProps('st_full_name', 'Nome Completo'),
        sorter: (a, b) => a.st_full_name.length - b.st_full_name.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
      },
      {
        dataIndex: 'st_vacancy',
        title: 'Vaga Candidatura',
        key: 'st_vacancy',
        ...getColumnSearchProps('st_vacancy', 'Vaga de Candidatura'),
        sorter: (a, b) => a.st_vacancy.length - b.st_vacancy.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
      },
      {
        dataIndex: 'dt_insert',
        title: 'Data de Cadastro',
        key: 'dt_insert',
        ...getColumnSearchProps('dt_insert', 'Data de Cadastro'),
        sorter: (a, b) => a.dt_insert.length - b.dt_insert.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        render: (text) => <p>{moment(text).format('DD/MM/YYYY')}</p>,
      },
      {
        dataIndex: 'st_email',
        title: 'E-mail',
        key: 'st_email',
        ...getColumnSearchProps('st_email', 'E-mail'),
        sorter: (a, b) => a.st_email.length - b.st_email.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
      },
    ];
    return columns;
  };

  return (
    <>
    <ToastContainer />
      <div className="page-content">
        <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Candidatos</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                nameOfResquest="candidates"
                btnNew
                setData={setData}
                cdPage="cd_candidate"
                routePermissions="candidates"
                route="/Recrutamento/Candidatos/Registro"
                getData={() => getData()}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
