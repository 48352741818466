import React, { useState } from 'react';
import { AlignLeftOutlined, HomeFilled, ContactsFilled } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './style.scss';

// Função auxiliar para criar os itens do menu
const createMenuItem = (key, label, icon, children) => ({
  key,
  icon,
  label,
  children,
});

// Estrutura dos itens do menu
const items = [
  createMenuItem('1', 'Apontamento', <HomeFilled />, [
    createMenuItem('23', 'Cargos', <AlignLeftOutlined />, [
      createMenuItem('231', <Link to="/Apontamento/Cargo">Listar</Link>),
      createMenuItem('232', <Link to="/Apontamento/Cargo/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('24', 'Colaboradores', <AlignLeftOutlined />, [
      createMenuItem('241', <Link to="/Apontamento/Colaboradores">Listar</Link>),
      createMenuItem('242', <Link to="/Apontamento/Colaboradores/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('25', 'Pessoa jurídica', <AlignLeftOutlined />, [
      createMenuItem('251', <Link to="/Apontamento/Pessoa-Juridica">Listar</Link>),
      createMenuItem('252', <Link to="/Apontamento/Pessoa-Juridica/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('26', 'Projetos', <AlignLeftOutlined />, [
      createMenuItem('261', <Link to="/Apontamento/Projeto">Listar</Link>),
      createMenuItem('262', <Link to="/Apontamento/Projeto/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('27', 'Tipo de Contato', <AlignLeftOutlined />, [
      createMenuItem('271', <Link to="/Apontamento/Tipo-Contato">Listar</Link>),
      createMenuItem('272', <Link to="/Apontamento/Tipo-Contato/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('28', 'Tipo de Endereço', <AlignLeftOutlined />, [
      createMenuItem('281', <Link to="/Apontamento/Tipo-Endereco">Listar</Link>),
      createMenuItem('282', <Link to="/Apontamento/Tipo-Endereco/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('29', 'Tipo de PJ', <AlignLeftOutlined />, [
      createMenuItem('291', <Link to="/Apontamento/Tipo-pj">Listar</Link>),
      createMenuItem('292', <Link to="/Apontamento/Tipo-pj/Registro">Cadastrar</Link>),
    ]),
  ]),

  createMenuItem('2', 'Recrutamento', <ContactsFilled />, [
    createMenuItem('31', 'Candidatos', <AlignLeftOutlined />, [
      createMenuItem('32', <Link to="/Recrutamento/Candidatos">Listar</Link>),
      createMenuItem('33', <Link to="/Recrutamento/Candidatos/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('41', 'Níveis', <AlignLeftOutlined />, [
      createMenuItem('42', <Link to="/Recrutamento/Niveis">Listar</Link>),
      createMenuItem('43', <Link to="/Recrutamento/Niveis/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('51', 'Requisitos', <AlignLeftOutlined />, [
      createMenuItem('52', <Link to="/Recrutamento/Requisitos">Listar</Link>),
      createMenuItem('53', <Link to="/Recrutamento/Requisitos/Registro">Cadastrar</Link>),
    ]),
    createMenuItem('61', 'Vagas', <AlignLeftOutlined />, [
      createMenuItem('62', <Link to="/Recrutamento/Vagas">Listar</Link>),
      createMenuItem('63', <Link to="/Recrutamento/Vagas/Registro">Cadastrar</Link>),
    ]),
  ]),
];

// Função que mapeia os níveis dos itens do menu
const getLevelKeys = (items) => {
  const key = {};
  const func = (items, level = 1) => {
    items.forEach((item) => {
      key[item.key] = level;
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items);
  return key;
};

const levelKeys = getLevelKeys(items);

const SidebarMenu = () => {
  const [stateOpenKeys, setStateOpenKeys] = useState([]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => !stateOpenKeys.includes(key));
    if (currentOpenKey) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <Menu
      mode="inline"
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      theme="dark"
      style={{ borderRadius: '10px', border: '2px outset black', background: '#262626', marginTop: '75px', marginLeft: '10px', marginBottom: '10px', width: 256 }}
      items={items}
      //id="menu-antd"
    />
  );
};

export default SidebarMenu;
