import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../../services/api';
import { excludeRequest, scrollTop } from '../../../../Admin/assets/scripts/global';

import './style.scss';

export default function RequirementsRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.split('=').length > 1;
  const id = isEdit ? location.search.split('=')[1] : null;

  function fillFields(data) {
    document.getElementById('requirement').checked = data.en_type === 'Requisito';
    document.getElementById('qualification').checked = data.en_type === 'Qualificação';
    document.getElementById('both').checked = data.en_type === 'Ambos';
    document.getElementById('descriptionField').value = data.st_description;
  }

  function getRequeriment() {
    const data = {
      params: { id },
    };

    try {
      const response = AppointmentApi.get('/RequirementQualifications',data)
      fillFields(response.data);
    } catch (err) {
      toast.error('Servidor indisponivel', err.message);
    }
  }

  // Limpar campos
  function clearFields() {
    document.getElementById('descriptionField').value = '';
    document.getElementById('requirement').checked = true;
    document.getElementById('qualification').checked = false;
    document.getElementById('both').checked = false;
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    const description = document.getElementById('descriptionField');
    const requirementType = document.querySelectorAll('input[name="choice"]:checked')[0].value;

    if (requirementType !== 'R' && requirementType !== 'Q' && requirementType !== 'A') {
      isValid = false;
    }

    if (description.value === '') {
      description.classList.add('border-danger');
      isValid = false;
    } else {
      description.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição insert
  async function RequirementInsert(redirect) {
    if (validateFields()) {
      // Desabilitar botões
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      let data = {
        st_description: document.getElementById('descriptionField').value,
        en_type: document.querySelectorAll('input[name="choice"]:checked')[0].value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };

      if (isEdit) {
        data.cd_requirement_qualification = id;
      }

      try {
        const response = await AppointmentApi({
          method: isEdit ? 'PUT' : 'POST',
          url: '/RequirementQualifications',
          data: JSON.stringify(data),
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(response.data);
          document.getElementById('save').disabled = false;

          if (redirect) {
            navigate('/Recrutamento/Requisitos');
          } else {
            clearFields();
            scrollTop();

            if (isEdit) {
              document.getElementById('delete').disabled = false;
            } else {
              document.getElementById('saveAndNew').disabled = false;
            }
            document.getElementById('save').disabled = false;
          }
        }
      } catch (err) {
        toast.error(err.message);
        document.getElementById('save').disabled = false;

        if (isEdit) {
          document.getElementById('delete').disabled = false;
        } else {
          document.getElementById('saveAndNew').disabled = false;
        }
      }
    }
  }


  useEffect( () => {
    const fetchData = async () => {
      if (isEdit) {
        getRequeriment();
      } else {
        clearFields()
      }
    };
    fetchData();
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="page-content requirement-register">
        <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Recrutamento/Requisitos">Requisitos/Qualificações</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Requisitos</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label className="text-color-light" htmlFor="descriptionField">
                  Descrição
                  <input id="descriptionField" type="text" className="mt-2" />
                </label>
              </div>

              {/* Row 2 */}
              <div className="d-flex mt-4">
                <label
                  className="form-check-label d-flex align-items-center me-3"
                  htmlFor="requirement"
                >
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="choice"
                    value="R"
                    id="requirement"
                    defaultChecked={!isEdit}
                  />
                  Requisito
                </label>
                <label
                  className="form-check-label d-flex align-items-center me-3"
                  htmlFor="qualification"
                >
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="choice"
                    value="Q"
                    id="qualification"
                  />
                  Qualificação
                </label>
                <label className="form-check-label d-flex align-items-center" htmlFor="both">
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="choice"
                    value="A"
                    id="both"
                  />
                  Ambos
                </label>
              </div>

              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      RequirementInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        RequirementInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    RequirementInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      RequirementInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'RequirementQualifications');
                    navigate('/Recrutamento/Requisitos');
                  }}
                >
                  Excluir
                </button>
                <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Alerta de Resposta */}
        <div id="response" className="alert" style={{ display: 'none' }} role="alert" />
      </div>
    </>
  );
}
