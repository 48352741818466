import axios from 'axios';

export const AdminApi = axios.create({
  // Homologação
  //baseURL: 'https://nbwdigital.com.br/nbwdigital.com/wwwroot/ApiController.dll/Controllers',
  //baseURL: 'https://nbwdigital.com.br',
  //baseURL: 'https://nbwdigital.com.br/dev/phpApi/src/Controllers',
  //baseURL: 'ftp://40_admin@192.99.254.197/40_admin/nbwdigital.com/wwwroot',
  baseURL: 'http://localhost/nbwadm/src/Controllers', // Local

  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'max-age=31536000',
    Accept: '*/*',
  },
  withCredentials: true,
  credentials: 'same-origin',
});

export const WebsiteApi = axios.create({
  //baseURL: 'https://nbwdigital.com.br/nbwdigital.com/wwwroot/ApiController.dll/Controllers',
  //baseURL: 'https://nbwdigital.com.br/dev/C#_api/ApiController/Controllers',
  baseURL: 'http://localhost/nbwadm/src/Controllers', // Local
  //baseURL: 'https://nbwdigital.com.br/dev/phpApi/src/Controllers', // Homologação
  //baseURL: 'ftp://40_admin@192.99.254.197/40_admin/nbwdigital.com/wwwroot',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'max-age=31536000',
    Accept: '*/*',
  },
  withCredentials: true,
  credentials: 'same-origin',
});

export const AppointmentApi = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('nbw__token')}`,
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'max-age=31536000',
  },
  withCredentials: true,
  credentials: 'same-origin',
});

AppointmentApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('nbw__token');
      window.location.href = '/Login';
    } else if (error.response.status === 403) {
      window.location.href = '/Error?error=403';
    }
    return error;
  }
);
