import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import selectStyle from '../../../../Admin/components/Shared/selectStyle';
import { MenuList, Option, ValueContainer, } from '../../../../Admin/components/Shared/selectComponents';
import { AppointmentApi } from '../../../../../services/api';
import statesCities from '../../../../Website/assets/scripts/statesCities.json';
import { scrollTop } from '../../../../Website/assets/scripts/global';
import { excludeRequest, validateEmail } from '../../../../Admin/assets/scripts/global';

import './style.scss';

let candidateData = {};
//let optionsFilled = false;

let requirementOptions = [];
let levelOptions = [];
let regimeOptions = [];
let vacancyOptions = [];
let stateOptions = [];
let cityOptions = [];

// Realiza o request para trazer as options dos selects do banco
async function fillSelectOptions() {
  requirementOptions = [];
  levelOptions = [];
  regimeOptions = [];
  vacancyOptions = [];
  stateOptions = [];
  cityOptions = [];

  const data = {
    params: { screen: true, id: window.location.href.split('=')[1] },
  };

  try {
    const response = await AppointmentApi.get('/Candidates/GetScreen', data);
    candidateData = response.data.candidate;
    Object.values(response.data.requirementQualifications).map((valueRequirement) =>
      requirementOptions.push({
        label: valueRequirement.st_description,
        value: valueRequirement.cd_requirement_qualification,
      })
    );
    Object.values(response.data.knowledgeLevels).map((level) =>
      levelOptions.push({
        label: level.st_description,
        value: level.cd_level
      })
    );
    Object.values(response.data.employmentRegimes).map((regime) =>
      regimeOptions.push({ label: regime.st_description, value: regime.cd_employment_regime })
    );
    Object.values(response.data.vacancies).map((vacancy) =>
      vacancyOptions.push({ label: vacancy.st_title, value: vacancy.cd_vacancy })
    );
  } catch (err) {
    toast.error(err.message);
  }

  statesCities.states.forEach((state) => {
    stateOptions.push({ label: state.name, value: state.acronym });
  });
}

export default function CandidatesRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.split('=').length > 1;
  const id = isEdit ? location.search.split('=')[1] : null;
  const [optionsFilled, setOptionsFilled] = useState(false);

  const [stateRequirementOptions, setStateRequirementOptions] = useState([]);
  const [stateLevelOptions, setStateLevelOptions] = useState([]);
  const [stateRegimeOptions, setStateRegimeOptions] = useState([]);
  const [stateVacancyOptions, setStateVacancyOptions] = useState([]);
  const [stateCityOptions, setStateCityOptions] = useState([]);

  const [refRequirement, setRefRequirement] = useState();
  const [refState, setRefState] = useState();
  const [refCity, setRefCity] = useState();
  const [refLevel, setRefLevel] = useState();
  const [refRegime, setRefRegime] = useState();
  const [refVacancy, setRefVacancy] = useState();

  // Limpa o optionsFilled para realizar preenchimento dos campos novamente
  const handleNavigation = () => {
    const localizacao = location.pathname + location.search.split('=')[0];
    if (localizacao !== '/Recrutamento/Candidatos/Registro?cd_candidate') {
      setOptionsFilled(false);
    }
  };

  // Preenche o select de cidades
  function fulfillCitySelect(acronym) {
    cityOptions.length = 0;

    statesCities.states.forEach((state) => {
      if (state.acronym === acronym) {
        state.citys.forEach((city) => {
          cityOptions.push({ label: city, value: city });
        });
        setStateCityOptions(cityOptions);
      }
    });
  }

  // Preenche os campos em tela com os dados do candidato
  function fillFields() {
    const data = candidateData;

    if (data && data !== undefined) {
      document.getElementById('nameField').value = data.st_full_name;
      document.getElementById('emailField').value = data.st_email;
      document.getElementById('phoneField').value = data.st_phone;
      document.getElementById('linkedinField').value = data.lk_link_linkedin;
      if (data.up_cv_path) {
        const fileName = data.up_cv_path.split('.')[0];
        document.getElementsByClassName('input-file-label')[0].innerText = fileName;
      }

      const temp = [];
      data.ar_qualification
        .split('; ')
        .map((value) =>
          temp.push(requirementOptions.filter((obj) => obj.value.toString() === value)[0])
        );
      if (refRequirement.select && refRequirement.select.getValue() && refRequirement !== undefined) {
        refRequirement.select.setValue(temp);
      }
      if (refState.select && refState.select.getValue() && refState !== undefined) {
        refState.select.setValue(stateOptions.filter((obj) => obj.value === data.js_state)[0]);
      }
      fulfillCitySelect(data.js_state);
      if (refCity.select && refCity !== undefined) {
        refCity.select.setValue(cityOptions.filter((obj) => obj.label === data.js_city)[0]);
      }
      if (refLevel.select && refLevel !== undefined) {
        refLevel.select.setValue(levelOptions.filter((obj) => obj.value === data.cd_level)[0]);
      }
      if (refRegime.select && refRegime !== undefined) {
        refRegime.select.setValue(
          regimeOptions.filter((obj) => obj.value === data.cd_current_regime)[0]
        );
      }
      if (refVacancy.select && refVacancy !== undefined) {
        refVacancy.select.setValue(
          vacancyOptions.filter((obj) => obj.value === data.cd_application_vacancy)[0]
        );
      }
      if (data.fl_working === 1) {
        document.getElementById('workingCheckbox').checked = true;
      } else {
        document.getElementById('workingCheckbox').checked = false;
      }
      if (data.fl_address_change === 1) {
        document.getElementById('addressChangeCheckbox').checked = true;
      } else {
        document.getElementById('addressChangeCheckbox').checked = false;
      }
    }
  }

  // Limpa os campos
  function clearFields() {
    document.getElementById('nameField').value = '';
    document.getElementById('phoneField').value = '';
    document.getElementById('emailField').value = '';
    document.getElementById('workingCheckbox').checked = false;
    document.getElementById('addressChangeCheckbox').checked = false;
    document.getElementById('linkedinField').value = '';
    document.getElementById('fileField').value = '';
    document.getElementsByClassName('input-file-label')[0].innerText = 'Nenhum arquivo selecionado';

    if (refRequirement.select && refRequirement.select.getValue() && refRequirement !== undefined) {
      refRequirement.select.clearValue();
    }

    if (refCity.select && refCity.select.getValue() && refCity !== undefined) {
      refCity.select.clearValue();
    }

    if (refState.select && refState.select.getValue() && refState !== undefined) {
      refState.select.clearValue();
    }

    if (refLevel.select && refLevel.select.getValue() && refLevel !== undefined) {
      refLevel.select.clearValue();
    }

    if (refRegime.select && refRegime.select.getValue() && refRegime !== undefined) {
      refRegime.select.clearValue();
    }

    if (refVacancy.select && refVacancy.select.getValue() && refVacancy !== undefined) {
      refVacancy.select.clearValue();
    }

    // refCity.select.clearValue();
    // refState.select.clearValue();
    // refLevel.select.clearValue();
    // refRegime.select.clearValue();
    // refVacancy.select.clearValue();
  }

  // Concatena os valores do multiselect
  function concatenateMultiSelectValue(multiselect) {
    const data = multiselect.map((el) => el.value);
    let result = '';

    for (let index = 0; index < data.length; index += 1) {
      result += `${data[index]}; `;
    }

    return result;
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    const nameField = document.getElementById('nameField');
    const phoneField = document.getElementById('phoneField');
    const emailField = document.getElementById('emailField');
    const linkedin = document.getElementById('linkedinField');
    const fileField = document.getElementById('fileField');
    const state = document.getElementsByClassName('select__control')[0];
    const city = document.getElementsByClassName('select__control')[1];
    const vacancy = document.getElementsByClassName('select__control')[4];

    if (fileField.value === '' && !isEdit) {
      fileField.classList.add('border-danger');
      isValid = false;
    } else {
      fileField.classList.remove('border-danger');
    }

    if (nameField.value === '') {
      nameField.classList.add('border-danger');
      isValid = false;
    } else {
      nameField.classList.remove('border-danger');
    }

    if (refState.select && refState.select.getValue() && refState.select.getValue()[0] === undefined) {
      state.style.border = '1px #cf4141 solid';
      isValid = false;
    } else {
      state.style.border = '0px';
    }

    //if (refCity.select.getValue()[0] === undefined) {
    if (refCity.select && refCity.select.getValue() && refCity.select.getValue()[0] === undefined) {
      city.style.border = '1px #cf4141 solid';
      isValid = false;
    } else {
      city.style.border = '0px';
    }
    if (refVacancy.select && refVacancy.select.getValue() && refVacancy.select.getValue()[0] === undefined) {
      vacancy.style.border = '1px #cf4141 solid';
      isValid = false;
    } else {
      vacancy.style.border = '0px';
    }

    if (linkedin.value === '') {
      linkedin.classList.add('border-danger');
      isValid = false;
    } else {
      linkedin.classList.remove('border-danger');
    }

    if (document.getElementById('emailField').value === '') {
      emailField.classList.add('border-danger');
      isValid = false;
    } else if (validateEmail(emailField.value)) {
      emailField.classList.remove('border-danger');
    } else {
      emailField.classList.add('border-danger');
      toast.error('Campos obrigatórios não preenchidos!');
      isValid = false;
    }

    if (phoneField.value === '') {
      phoneField.classList.add('border-danger');
      isValid = false;
    } else {
      phoneField.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição insert de candidato
  function CandidateInsert(redirect) {
    if (validateFields()) {
      if (isEdit) {
        document.getElementById('delete').disabled = true;
      } else {
        document.getElementById('saveAndNew').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const formdata = new FormData();
      if (isEdit) {
        formdata.append('cd_candidate', id);
      }
      formdata.append('st_full_name', document.getElementById('nameField').value);
      formdata.append('st_email', document.getElementById('emailField').value);
      formdata.append('st_phone', document.getElementById('phoneField').value);
      // formdata.append('js_city', document.getElementsByClassName('select__control')[0]);
      // formdata.append('js_state', document.getElementsByClassName('select__control')[1]);
      // formdata.append('cd_application_vacancy', document.getElementsByClassName('select__control')[4]);
      formdata.append('js_city', refCity.select ? refCity.select.getValue()[0].value : '');
      formdata.append('js_state', refState.select ? refState.select.getValue()[0].value : '');
      formdata.append('cd_application_vacancy', refVacancy.select ? refVacancy.select.getValue()[0].value : '');
      formdata.append('lk_link_linkedin', document.getElementById('linkedinField').value);
      formdata.append('fl_working', document.getElementById('workingCheckbox').checked ? 1 : 0);
      formdata.append(
        'fl_address_change',
        document.getElementById('addressChangeCheckbox').checked ? 1 : 0
      );
      if (refRequirement.select && refRequirement.select.getValue() && refRequirement.select.getValue() !== undefined) {
        formdata.append(
          'ar_qualification',
          concatenateMultiSelectValue(refRequirement.select.getValue())
        );
      }
      if (refLevel.select && refLevel.select.getValue() && refLevel.select.getValue() !== undefined) {
        formdata.append('cd_level', refLevel.select.getValue()[0].value);
      }
      if (refRegime.select && refRegime.select.getValue() && refRegime.select.getValue() !== undefined) {
        formdata.append('cd_current_regime', refRegime.select.getValue()[0].value);
      }

      if (
        document.getElementById('fileField').value !== '' &&
        document.getElementById('fileField').files[0] !== undefined
      ) {
        formdata.append('file', document.getElementById('fileField').files[0]);
        formdata.append('up_cv_path', document.getElementById('fileField').value);
      }
      formdata.append('cd_user', JSON.parse(localStorage.getItem('nbw__userData')).id);

      const headers = {
        'headers': {
          'Content-Type': 'multipart/form-data'
        }
      };

      try {
        const response = AppointmentApi.post('/Candidates', formdata, headers);

        if (response.status === 200 || response.status === 201) {
          toast.success(response.data);
          clearFields();

          if (isEdit) {
            document.getElementById('delete').disabled = false;
          } else {
            document.getElementById('saveAndNew').disabled = false;
          }
          document.getElementById('save').disabled = false;

          if (redirect) {
            navigate('/Recrutamento/Candidatos')
          } else {
            scrollTop();
          }
        }
      } catch (err) {
        toast.error(`Erro durante a requisição: ${err.statusText}`);

        if (isEdit) {
          document.getElementById('delete').disabled = false;
        } else {
          document.getElementById('saveAndNew').disabled = false;
        }
        document.getElementById('save').disabled = false;
      }
    }
  }

  // function para validar input file
  function validateFileExtension() {
    const file = document.getElementById('fileField');
    // const formResponse = document.getElementById('response');

    if (file && file !== undefined) {
      file.onchange = () => {
        if (file.value.split('.')[1]?.toLowerCase() !== 'pdf') {
          toast.error('Insira um arquivo PDF!');
          file.value = '';
          document.getElementsByClassName('input-file-label')[0].innerText =
            'Nenhum arquivo selecionado';
        } else {
          const fileName = file.value.split('\\')[2].split('.')[0];
          document.getElementsByClassName('input-file-label')[0].innerText = fileName;
        }
      };
    }
  }

  // Preenche os selects com as options
  function fillSelects() {
    setStateRequirementOptions(requirementOptions);
    setStateLevelOptions(levelOptions);
    setStateVacancyOptions(vacancyOptions);
    setStateRegimeOptions(regimeOptions);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!isEdit) {
        if (refRequirement !== undefined) {
          await clearFields();
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!optionsFilled) {
        await fillSelectOptions();
        fillSelects();
        handleNavigation();
      }

      if (isEdit) {
        await fillFields();
      }
      validateFileExtension();
    }
    //Inputmask({ mask: '(99) [9] 9999-9999' }).mask(document.getElementById('phoneField'));
    fetchData();
  }, [optionsFilled]);

  return (
    <>
    <ToastContainer/>
      <div className="page-content candidate-register-row">
        <div style={{ border: '2px inset black', borderRadius: '10px' }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Recrutamento/Candidatos">Candidatos</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Candidato</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  htmlFor="nameField"
                >
                  Nome completo
                  <input type="text" className="mt-2" id="nameField" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  htmlFor="emailField"
                >
                  E-mail
                  <input id="emailField" className="mt-2" type="text" />
                </label>
              </div>

              {/* Row 2 */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Qualificações
                  <Select
                    options={stateRequirementOptions}
                    ref={(ref) => {
                      setRefRequirement(ref);
                    }}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    className="multiselect-container mt-2"
                    classNamePrefix="multiselect"
                    components={{ MenuList, Option, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  htmlFor="phoneField"
                >
                  Telefone
                  <input
                    id="phoneField"
                    className="mt-2"
                    placeholder="(00) 9 0000-0000"
                    type="text"
                  />
                </label>
              </div>

              {/* Row 3 */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Estado
                  <Select
                    options={stateOptions}
                    ref={(ref) => {
                      setRefState(ref);
                    }}
                    closeMenuOnSelect
                    hideSelectedOptions={false}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    onChange={(e) => {
                      if (e !== null) {
                        fulfillCitySelect(e.value);
                      }
                    }}
                    components={{ MenuList, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Cidade
                  <Select
                    options={stateCityOptions}
                    ref={(ref) => {
                      setRefCity(ref);
                    }}
                    closeMenuOnSelect
                    hideSelectedOptions={false}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    components={{ MenuList, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>
              </div>

              {/* Row 4 */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Nível de conhecimento
                  <Select
                    options={stateLevelOptions}
                    ref={(ref) => {
                      setRefLevel(ref);
                    }}
                    closeMenuOnSelect
                    hideSelectedOptions={false}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    components={{ MenuList, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Regime atual
                  <Select
                    options={stateRegimeOptions}
                    ref={(ref) => {
                      setRefRegime(ref);
                    }}
                    closeMenuOnSelect
                    hideSelectedOptions={false}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    components={{ MenuList, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>
              </div>

              {/* Row 5 */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  Vaga candidatura
                  <Select
                    options={stateVacancyOptions}
                    ref={(ref) => {
                      setRefVacancy(ref);
                    }}
                    closeMenuOnSelect
                    hideSelectedOptions={false}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    components={{ MenuList, ValueContainer }}
                    placeholder="Selecione..."
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    styles={selectStyle}
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  htmlFor="linkedinField"
                >
                  Perfil Linkedin
                  <input
                    id="linkedinField"
                    className="mt-2"
                    placeholder="https://www.linkedin.com/in/"
                    type="text"
                  />
                </label>
              </div>

              {/* Row 6 */}
              <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 actions custom-input-file">
                  <p className="custom-label text-color-light">Currículo</p>
                  <input className="input-file" id="fileField" multiple type="file" />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="input-file-label text-center">Nenhum arquivo selecionado</label>
                </div>

                <div className="d-flex justify-content-start col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-check">
                    <label className="form-check-label me-3" htmlFor="workingCheckbox">
                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        id="workingCheckbox"
                      />
                      Atualmente empregado
                    </label>
                  </div>

                  <div className="form-check">
                    <label className="form-check-label" htmlFor="addressChangeCheckbox">
                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        id="addressChangeCheckbox"
                      />
                      Disponível para mudança
                    </label>
                  </div>
                </div>
              </div>

              {/* Row 7 */}
              <div className="mt-3 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      CandidateInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        CandidateInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    CandidateInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      CandidateInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'Candidates');
                    navigate('/Recrutamento/Candidatos');
                  }}
                >
                  Sim
                </button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Alerta de Resposta */}
        <div id="response" className="alert" style={{ display: 'none' }} role="alert" />
      </div>
    </>
  );
}
